

















































































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { carriers, loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import {
  IDialogAndButtonAction,
  IPaymentDetails,
  IProduct,
  IResolveAccount,
  IUser,
  IUSSDServiceCodes,
  PayloadState,
} from '@/types/types';
const ussdCodesModule = namespace('ussdCodes');
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { numeric, required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Field is required',
});

@Component({
  name: 'EditUSSDCodeDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class EditUSSDCodeDialog extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @ussdCodesModule.Getter('getResetFormValues') resetFormValues!: IUSSDServiceCodes;
  @ussdCodesModule.Getter('getUssdServiceCodesDetails') details!: IUSSDServiceCodes;

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;

  ussdCode: Pick<IUSSDServiceCodes, 'consumer' | 'description' | 'apiUrl' | 'code'> = {
    consumer: '',
    description: '',
    apiUrl: '',
    code: '',
  };

  @Watch('details')
  onDetailsChange(payload: IUSSDServiceCodes): void {
    this.ussdCode = { ...payload };
  }

  @Watch('resetFormState')
  onFormChange(payload: boolean) {
    if (payload) {
      this.ussdCode = { ...this.resetFormValues };
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Emit('ussdCode')
  editUSSDServiceCode() {
    return {
      loading: {
        idx: 'edit',
        state: false,
      },
      body: {
        ...this.ussdCode,
      },
    };
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'edit',
      state: false,
    };
  }
}
